import React from 'react';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import './about.css'

const About = (props) => {
	let history = useHistory()
	const showAlert = () => {
		if (history.location.state) {
			if (history.location.state['login']) {
				return <Alert variant="success" >Login effettuato con successo</Alert>;
			}
			if (history.location.state) {
				if (history.location.state['successReason']) {
					return <Alert variant='success'>{history.location.state['successReason']}</Alert>
				}
			}
		}
	}

	const content = () => {
		if (props.loginStatus['sub']) {
			return (
				<div className='scritte'>
				{/* <a href='/course'>
					<Button
						className="col-md-7"
						size="lg"
						variant="primary">
						Vai al corso "Social Work Day"
					</Button>
					</a> */}
					<p className="scritte">Hai effettuato l'accesso correttamente</p>
					{/* <p className="scritte">28 Ottobre: dalle 9:00 alle 13:00</p>
					<p className="scritte">4 Novembre: dalle 9:00 alle 13:00</p>
					<p className="scritte">18 Novembre: dalle 9:00 alle 13:00</p>
					<p className="scritte">2 Dicembre: dalle 9:00 alle 13:00</p>
					<p className="scritte">13 Gennaio: dalle 9:00 alle 13:00</p>*/}
					<p className="scritte">Il social Work Day 2024 si svolgerà giorno 14 Giugno dalle 14:30 alle 18:30</p>
					<p><Button href='/course/10' size='lg'>Partecipa qui all'evento</Button></p> 
					{/* <p><Button variant='secondary' size='lg'>Grazie per aver partecipato</Button></p> */}




				</div>
			)
		}
		else {
			return (
				<div>
					<div className='scritte'>
						Per partecipare alla prossima conferenza <a href='/register'>registrati cliccando qui</a> o con il pulsante in alto a destra<br></br><br></br>
						Se ti sei già registrato <a href='/login'> Accedi cliccando qui</a> o con il pulsante "login" in alto a destra
					</div>
				</div>
			)
		}
	}
	return (
		<Container style={{ maxWidth: '100%', padding: "0" }}>
			<Container>
				{showAlert()}
			</Container>
			<div className='headerDiv mb-5'>
			<img src='/Cover.png' className='headerImage '></img>
			</div>
			{content()}
		</Container>
	);
};

export default About;
